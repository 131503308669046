import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import qs from 'query-string';
import React, { useEffect } from "react";

function Done() {
    useEffect(() => {
        const parsed = qs.parse(location.hash);
        const code = parsed.code;
        window.parent.postMessage({ done: true, code }, '*');

    }, []);
    return (
        <Row justify="center">
            <Col><LoadingOutlined style={{fontSize: 64, color: "#2A94F4"}} color="#2A94F4" spin /></Col>
        </Row>
    );
}

export default Done;
